import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import { Container, Row, Col } from 'reactstrap'

import Layout from '../components/layout'

import { css } from 'react-emotion'

import CategoryDisplay from '../components/category-display'
import TagDisplay from '../components/tag-display'
import Sidebar from '../components/sidebar'

import { FaTwitter, FaYoutube, FaEnvelopeOpenText } from 'react-icons/fa'

import { DiscussionEmbed } from 'disqus-react'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const siteDescription = post.excerpt
    const { previous, next } = this.props.pageContext

    const disqusShortname = 'developingauthority'
    const disqusConfig = {
      //identifier: post.url,
      title: post.frontmatter.title,
    }

    const postStyles = css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 30px 0;
      }

      p:first-of-type {
        margin-top: 0px;
      }

      p {
        font-size: 1.3em;
        margin-bottom: 30px;
        margin-top: 30px;
        overflow: auto;
      }

      img {
        display: block;
        margin: 5px auto 5px auto;
        max-width: 100%;
        width: auto;
        height: auto;
      }

      strong {
        font-weight: 700 !important;
      }

      ul,
      ol {
        margin-left: 20px;
        margin-bottom: 40px;
      }

      li {
        font-size: 1.1em;
        line-height: 1.8em;
      }

      .language-text {
        background-color: #f1f0f0 !important;
        padding: 5px !important;
        color: #000 !important;
        font-size: 18px;
        text-shadow: none !important;
        font-family: monospace;
      }
    `

    const videoStyles = css`
      .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
      }
      .embed-container iframe,
      .embed-container object,
      .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    `

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[
            { name: 'description', content: post.frontmatter.description },
            { property: 'og:type', content: 'article' },
            { property: 'og:title', content: post.frontmatter.title },
            {
              property: 'og:description',
              content: post.frontmatter.description,
            },
            {
              property: 'article:published_time',
              content: post.frontmatter.published_unformatted,
            },
            {
              property: 'article:modified_time',
              content: post.frontmatter.updated_unformatted,
            },
            { property: 'og:site_name', content: siteTitle },
            {
              property: 'og:image',
              content:
                'https://developingauthority.com' +
                post.frontmatter.featured_image,
            },
            { property: 'og:image:width', content: '500' },
            { property: 'og:image:height', content: '500' },
            { property: 'og:locale', content: 'en_US' },
            { name: 'twitter:text:title', content: post.frontmatter.title },
            {
              name: 'twitter:image',
              content:
                'https://developingauthority.com' +
                post.frontmatter.featured_image,
            },
            { name: 'twitter:card', content: 'summary_large_image' },
            {
              name: 'google-site-verification',
              content: 'xxxxxx',
            },
          ]}
          title={`${post.frontmatter.title} | ${siteTitle}`}
        >
          <link
            rel="canonical"
            href={'https://developingauthority.com' + post.frontmatter.url}
          />
        </Helmet>

        <Container fluid={true} style={{}}>
          <article
            itemScope="itemscope"
            itemType="http://schema.org/BlogPosting"
            itemProp="blogPost"
            style={{
              backgroundColor: `#fff`,
              padding: `35px`,
            }}
          >
            <Row>
              <header
                itemScope="itemscope"
                itemType="http://schema.org/WPHeader"
                style={{
                  backgroundColor: `#f6f6f6`,
                  width: `100%`,
                  padding: `40px 40px 20px 40px`,
                  marginBottom: `30px`,
                }}
              >
                <CategoryDisplay details={post} />
                <h1>{post.frontmatter.title}</h1>

                <p
                  style={{
                    textTransform: `uppercase`,
                    marginBottom: `10px`,
                  }}
                >
                  <span
                    itemProp="author"
                    itemScope="itemscope"
                    itemType="http://schema.org/Person"
                  >
                    <small>
                      By
                      <a style={{ boxShadow: 'none' }} href={`/#about`}>
                        &nbsp;
                        {post.frontmatter.author}
                      </a>
                    </small>
                  </span>
                  <meta
                    itemProp="datePublished"
                    content={post.frontmatter.published_unformatted}
                  />
                  <small>
                    <time
                      itemProp="dateModified"
                      dateTime={post.frontmatter.updated_unformatted}
                    >
                      &nbsp;| Last Updated: {post.frontmatter.updated_formatted}
                    </time>
                  </small>
                </p>

                <TagDisplay details={post} />
              </header>
            </Row>

            <Row>
              <Col xs="12" md="9" lg="9">
                <div
                  className={postStyles}
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </Col>
              <Col md="3" lg="3" className="d-none d-md-block">
                <Sidebar />
              </Col>
            </Row>

            <Row style={{ fontSize: `1.2em`, marginTop: `40px` }}>
              <Col style={{ textAlign: `left` }} xs="6">
                {previous && (
                  <a href={previous.frontmatter.url} rel="prev">
                    ← {previous.frontmatter.title}
                  </a>
                )}
              </Col>
              <Col style={{ textAlign: `right` }} xs="6">
                {next && (
                  <a href={next.frontmatter.url} rel="next">
                    {next.frontmatter.title} →
                  </a>
                )}
              </Col>
            </Row>
          </article>

          <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
        </Container>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(frontmatter: { url: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        author
        url
        description
        published_unformatted: date
        published_formatted: date(formatString: "MMMM DD, YYYY")
        updated_unformatted: updated
        updated_formatted: updated(formatString: "MMMM DD, YYYY")
        featured_image
        tags
        categories
      }
    }
  }
`
